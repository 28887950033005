import React from "react";

import { withRouter } from "react-router-dom";
import Screen from "../../components/Screen";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import uuid from "uuid";
import FriendlyCaptcha from "../../components/FriendlyCaptcha";

class Newsletter extends Screen {
  constructor(props) {
    super(props);

    this.state = Object.assign(this.state, {
      errors: null,
      isCaptchaVisible: false,
      isCaptchaValidated: false,
    });
  }

  getIp() {
    return "IP_PUBLIC";
  }

  getIdSession() {
    return uuid.v4();
  }
  getParticipationDate() {
    return Math.trunc(Date.now() / 1000); // Date().now() fourni un timestamp en js. Il faut supprimer les 3 derniers chiffres pour avoir le timestamp en PHP;
  }

  validateInputs() {
    this.setState({ errors: null });
    const email = this.emailInput.value;
    const nom = this.nomInput.value;
    const prenom = this.prenomInput.value;
    const phone = this.phoneInput ? this.phoneInput.value : "";
    let error = false;
    let emailError = null;
    let nameError = null;
    let firstNameError = null;
    let phoneError = null;

    if (email === "") {
      error = true;
      emailError = { email: ["Ce champ est obligatoire"] };
    } else {
      const mailformat = /^(\s)*\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+(\s)*$/;
      if (!email.match(mailformat)) {
        error = true;
        emailError = { email: ["Cet email n'est pas valide"] };
      }
    }
    if (nom === "") {
      error = true;
      nameError = { nom: ["Ce champ est obligatoire"] };
    }
    if (prenom === "") {
      error = true;
      firstNameError = { prenom: ["Ce champ est obligatoire"] };
    }
    if (phone !== "") {
      const phoneFormat = /^(\+33|0033|0)(6|7)[0-9]{8}$/;
      if (!phone.match(phoneFormat)) {
        error = true;
        phoneError = { phone: ["Ce numéro de téléphone n'est pas valide"] };
      }
    }
    this.setState({
      errors: { ...firstNameError, ...nameError, ...emailError, ...phoneError },
    });
    return !error;
  }

  subscribe = (event) => {
    event.preventDefault();

    if (this.validateInputs()) {
      const idSession = this.getIdSession();
      const email = this.emailInput.value;
      // const participationDate = new Date().toISOString();
      const consentmentDate = new Date().toISOString();
      const participationDate = this.getParticipationDate();
      const ip = this.getIp();
      const nom = this.nomInput.value;
      const prenom = this.prenomInput.value;
      const mobile = this.phoneInput ? this.phoneInput.value : "";
      const score = 0;
      const answerCount = 0;
      const inscriptionFrom = "defi_tri_live";

      const optinInput = this.optinInput && this.optinInput.checked;
      const formGame = {
        idSession,
        email,
        participationDate,
        ip,
        nom,
        prenom,
        mobile,
        answerCount,
        score,
        hasOptinNewsLetter: !!optinInput,
        operationId: this.wording.getConfig("id"),
      };

      window.sessionStorage.setItem(
        "formParticipation",
        JSON.stringify(formGame)
      );

      // const formCheck = {
      //   email,
      //   operationId: this.wording.getConfig('id')
      // };
      this.api
        .checkEmail(formGame)
        .then((response) => {
          if (response.error) {
            throw response;
          }
          if (response) {
            window.sessionStorage.setItem("formParticipationId", response);
            return true;
          } else {
            throw "checkEmail"; // eslint-disable-line no-throw-literal
          }
        })
        .then((response) => {
          if (optinInput) {
            const form = {
              idSession,
              email,
              consentmentDate,
              ip,
              consentmentRgpd: true,
              consentmentEmail: true,
              inscriptionFrom: inscriptionFrom,
            };

            this.api
              .addPlayer(form)
              .then((responseOptin) => {
                if (!responseOptin.email) {
                  throw Error("error_add_player");
                }
                this.redirect("/validate");
              })
              .catch((e) => {
                console.log("Error:", e);
                this.redirect("/validate");
              });
          } else this.redirect("/validate");
        })
        .catch((e) => {
          console.log("Error:", e);
          if (
            e === "checkEmail" ||
            e["error"] === "email_already_participating"
          ) {
            this.setState({
              errors: {
                email: [
                  this.wording.getLabelNewLine(
                    "ERROR.API.EMAIL_ALREADY_PARTICIPATING"
                  ),
                ],
              },
            });
          } else if (e["error"] === "malformatted_request_not_json") {
            console.log("Error while sending data : json malformated");
          } else if (e["error"] === "malformatted_invalid_json") {
            console.log("Error while sending data : json malformated");
          } else if (e["error"] === "json_missing_parametter") {
            console.log("Error while sending data : parameters misssing");
            if (e["mandatory_parameters"] !== undefined) {
              console.log(
                "List of mandatory parameters : " +
                  e["mandatory_parameters"].join(", ")
              );
            }
          } else if (e["error"] === "operation_not_found") {
            console.log("Error while sending data : operation not found");
          } else if (e["error"] === "participation_date_invalid") {
            console.log("Error while sending data : date format invalid");
          } else if (e["error"] === "participation_id_not_found") {
            console.log(
              "Error while sending data : participation with id not found"
            );
          } else if (e["error"] === "email_with_id_not_found") {
            console.log(
              "Error while sending data : participation with id not found"
            );
          } else if (e["error"] === "participation_not_saved") {
            console.log("Error while sending data : participation not created");
          } else if (e["error"] === "error_add_player") {
            console.log(
              "Error while sending data : could not add newsletter information"
            );
          }
        });
    }
  };

  renderError(field) {
    if (!this.state.errors[field]) {
      return null;
    }

    return (
      <div className={`error error-${field}`}>
        {this.state.errors[field].map((error, key) => {
          return <div key={key}>{error}</div>;
        })}
      </div>
    );
  }

  goToValidate = () => {
    window.sessionStorage.removeItem("formParticipation");
    window.sessionStorage.removeItem("formParticipationId");
    this.api
      .scoreCreate({
        answerCount: 0,
        idSession: this.getIdSession(),
        ip: this.getIp(),
        operationId: this.wording.getConfig("id"),
        participationDate: this.getParticipationDate(),
        score: 0,
      })
      .then((createId) => {
        window.sessionStorage.setItem("formParticipationId", createId);
        this.redirect("/validate");
      })
      .catch((e) => {
        console.log("Error:", e);
      });
  };

  // Callback function to handle recaptcha verification
  handleRecaptchaValidated = (isValidated) => {
    this.setState({ isCaptchaValidated: isValidated });
  };

  handleOptin = () => {
    this.setState({ isCaptchaVisible: this.optinInput.checked });
  }

  render() {
    const ErrorClassNom =
      this.state.errors && this.state.errors.nom && this.renderError("nom")
        ? "error"
        : "";
    const ErrorClassPrenom =
      this.state.errors &&
      this.state.errors.prenom &&
      this.renderError("prenom")
        ? "error"
        : "";
    const ErrorClassEmail =
      this.state.errors && this.state.errors.email && this.renderError("email")
        ? "error"
        : "";
    const ErrorClassPhone =
      this.state.errors && this.state.errors.phone && this.renderError("phone")
        ? "error"
        : "";
    return (
      <div className="newsletter-page page">
        <Header
          NamePage={this.wording.getLabelNewLine(
            "DEFI_TRI_LIVE.NEWSLETTER.PAGE_NAME"
          )}
        />
        <div className="content-page">
          <form onSubmit={this.subscribe}>
            <div className="title-page">
              {this.wording.getConfigNewLine(
                  "titleNewsletter"
              )}
            </div>
            <div className="text-page">
              {this.wording.getConfigNewLine(
                  "descriptionNewsletter"
              )}
            </div>

            <div className="form-block">
              <div className={"input-block " + ErrorClassPrenom}>
                <input
                    className="input"
                    type="text"
                    id="prenom"
                    name="prenom"
                    ref={(input) => (this.prenomInput = input)}
                    placeholder={this.wording.getLabel(
                        "DEFI_TRI_LIVE.NEWSLETTER.PLACEHOLDER_FIRSTNAME"
                    )}
                />
                {this.state.errors && this.renderError("prenom")}
              </div>
              <div className={"input-block " + ErrorClassNom}>
                <input
                    className="input"
                    type="text"
                    id="nom"
                    name="nom"
                    ref={(input) => (this.nomInput = input)}
                    placeholder={this.wording.getLabel(
                        "DEFI_TRI_LIVE.NEWSLETTER.PLACEHOLDER_LASTNAME"
                    )}
                />
                {this.state.errors && this.renderError("nom")}
              </div>
              <div className={"input-block " + ErrorClassEmail}>
                <input
                    className="input"
                    type="text"
                    id="email"
                    name="email"
                    ref={(input) => (this.emailInput = input)}
                    placeholder={this.wording.getLabel(
                        "DEFI_TRI_LIVE.NEWSLETTER.PLACEHOLDER_EMAIL"
                    )}
                />
                {this.state.errors && this.renderError("email")}
              </div>

              {this.wording.getConfig("optinPhone") === true && (
                  <div className={"input-block " + ErrorClassPhone}>
                    <input
                        className="input"
                        type="text"
                        id="mobile"
                        name="mobile"
                        ref={(input) => (this.phoneInput = input)}
                        placeholder={this.wording.getLabel(
                            "DEFI_TRI_LIVE.NEWSLETTER.PLACEHOLDER_PHONE"
                        )}
                    />
                    <div className="condition-sms">
                      {this.wording.getLabelNewLine(
                          "DEFI_TRI_LIVE.NEWSLETTER.TEXT_SMS_CONDITIONS"
                      )}
                    </div>
                    {this.state.errors && this.renderError("phone")}
                  </div>
              )}
            </div>
            <div className="mandatory">
              {this.wording.getLabelNewLine(
                  "DEFI_TRI_LIVE.NEWSLETTER.TEXT_MANDATORY"
              )}
            </div>

            {this.wording.getConfig("optinNewsletter") === true && (
                <div className="checkbox-block">
                  <input
                      className="check"
                      type="checkbox"
                      id="optin"
                      name="optin"
                      value="true"
                      onClick={this.handleOptin}
                      ref={(input) => (this.optinInput = input)}
                  />
                  <label className="label" htmlFor="optin">
                    <span></span>
                    <div>
                      {this.wording.getLabelNewLine(
                          "DEFI_TRI_LIVE.NEWSLETTER.LABEL_OPTIN"
                      )}
                    </div>
                  </label>
                </div>
            )}

            <div className="condition">
              {this.wording.getLabelNewLine(
                  "DEFI_TRI_LIVE.NEWSLETTER.TEXT_CONDITIONS_HTML"
              )}
            </div>

            <div style={{ display: this.state.isCaptchaVisible ? 'block' : 'none' }}>
              <FriendlyCaptcha onCaptchaValidated={this.handleRecaptchaValidated}/>
            </div>

            {this.state.isCaptchaVisible && this.state.isCaptchaValidated && (<>
              <button className="btn default btn-inscription" type="submit">
                <span>
                  {this.wording.getLabel("DEFI_TRI_LIVE.NEWSLETTER.BTN_OPTIN")}
                </span>
              </button>
            </>)}
            <div className="ignore-sign" onClick={this.goToValidate}>
              {this.wording.getLabelNewLine("DEFI_TRI_LIVE.NEWSLETTER.BTN_NO_OPTIN")}
            </div>
          </form>
        </div>
        <div className="bottom-page">
          <Footer/>
        </div>
      </div>
    );
  }
}

export default withRouter(Newsletter);
