import React, { Component } from 'react'
import { Wording } from '../../library';
import {assetUrl} from "../../helpers/urlHelper";


export default class Footer extends Component {

  wording = Wording;
  render() {

    return (
      <div className="footer-block">
        <div className="block-citeo">
          <div className="line-pict"></div>
          <img src={assetUrl(this.wording.getConfig("footerLogoUrl"))} className="logo-citeo" alt={this.wording.getConfig("footerLogoAlt")}/>
          <div className="line-pict"></div>
        </div>

        { this.wording.getConfig("rulesLink") === true && 
        <div className="reglement-block">
          <a
              href={this.wording.getConfig("rulesLinkUrl")}
              target="_blank"
              rel="noopener noreferrer"
          >
              {this.wording.getConfigNewLine("rulesLinkLabel")}
          </a>
        </div>}
        <style dangerouslySetInnerHTML={{__html: `
            .btn.default{
              background-color: ${this.wording.getConfig("buttonColor")};
            }
        `}} />
      </div>
    )
  }

}