import React, {Component} from "react";
import { WidgetInstance } from 'friendly-challenge';

export default class FriendlyCaptcha extends Component {
    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.widget = React.createRef();
        this.onCaptchaValidated = this.props.onCaptchaValidated;
        this.state = {
            isCaptchaStarted: false
        };
    }

    componentDidMount() {
        if (!this.widget.current) {
            const onCaptchaValidated = this.onCaptchaValidated;
            this.widget.current = new WidgetInstance(this.container.current, {
                startMode: "none",
                sitekey: "FCMITMCHB0E2O4GS",
                doneCallback: function (solution) {
                    onCaptchaValidated(true);
                },
                errorCallback: function (err) {
                    onCaptchaValidated(false);
                }
            });
        }
    }

    componentDidUpdate() {
        if (!this.state.isCaptchaStarted) {
            this.widget.current.start();
            this.setState({isCaptchaStarted: true});
        }
    }

    componentWillUnmount() {
        if (this.widget.current) {
            this.widget.current.destroy();
        }
    }

    render() {
        return (
            <div ref={this.container} className="frc-captcha" data-lang="fr"/>
        );
    }
}
