import React, { Component } from "react";

export default class Logo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoBackgroundColor: "transparent",
      isShowLogo: true,
    };
  }

  componentDidMount() {
    this.setLogoBackgroundColorBasedOnURL();
  }

  setLogoBackgroundColorBasedOnURL() {
    const hostname = window.location.hostname;
    let backgroundColor = "transparent";
    let showBackground = true;

    if (hostname.includes("localhost")) {
      backgroundColor = "#D3D3D3";
    } else if (hostname.includes("dev-")) {
      backgroundColor = "#00FF00";
    } else if (hostname.includes("rec-")) {
      backgroundColor = "#0000FF";
    } else if (hostname.includes("pprd-")) {
      backgroundColor = "#FFA500";
    } else {
      showBackground = false;
    }

    this.setState({
      logoBackgroundColor: backgroundColor,
      isShowLogoBackground: showBackground,
    });
  }

  render() {
    const { logoBackgroundColor, isShowLogo } = this.state;
    const { logoUrl, logoAlt } = this.props;

    return (
      <>
        {isShowLogo && (
          <div
            className="logo-bg-color"
            style={{ backgroundColor: logoBackgroundColor }}
          >
            <img
              src={logoUrl}
              className="logo"
              alt={logoAlt}
            />
          </div>
        )}
      </>
    );
  }
}
