import WORDING from '../../assets/default/wording.json';
import DETAIL from '../../assets/default/detail.json'
import Api from '../Api';
import React from 'react'

class Wording {

  static getInstance() {
    if (!Wording._instance) {
      Wording._instance = new Wording();
    }

    return Wording._instance;
  }

  constructor() {
    WORDING.CONFIG = DETAIL
    this.labels = WORDING
  }

  init(operation) {
    return new Promise((resolve, reject) => {
      let promiseConf
      // if (operation === 'citeo') resolve(this)
      // else {
        promiseConf = [
          new Api(operation).getWording(),
          new Api(operation).getDetail(),
        ]
        Promise.all(promiseConf).then(([labels, config]) => {
          labels.CONFIG = config
          this.labels = this.extend(this.labels, labels);
          resolve(this)
        })
        .catch(e => {
            console.log("Unable to load wording", e);
            reject(false)
        });
      // }
    });
  }

  extend() {
    var self = this;
    var result = {}, obj;

    for (var i = 0; i < arguments.length; i++) {
      obj = arguments[i];
      for (var key in obj) {
        if (Object.prototype.toString.call(obj[key]) === '[object Object]') {
          if (typeof result[key] === 'undefined') {
            result[key] = {};
          }
          result[key] = self.extend(result[key], obj[key]);
        }
        else {
          result[key] = obj[key];
        }
      }
    }
    return result;
  }

  getLabel = (key, replaceDynamicValues = true) => {
      if(!key) {
          return '???';
      }

      if (key.indexOf('.') === -1) {
          return this.labels[key] ? this.replaceDynamicValues(this.labels[key], replaceDynamicValues) : `???${key}???`;
      }

      const keyArr = key.split('.');

      let val = keyArr.reduce((accumulator, value) => {
          if (!accumulator) {
              return '';
          }

          return accumulator[value];
      }, this.labels);

      if (typeof val === 'undefined') {
          console.error('missing wording key', key);
          return `???${key}???`;
      }

      return this.replaceDynamicValues(val, replaceDynamicValues);
  }

  replaceDynamicValues(val, replaceDynamicValues) {
      if (!replaceDynamicValues || typeof val !== 'string') {
          return val;
      }
      // Replace special value
      const find = '##CHRONO_DURATION##';
      const re = new RegExp(find, 'g');
      val = val.replace(re, this.getConfig("chronoDuration"));

      const find2 = '##OPERATION_TITLE##';
      const re2 = new RegExp(find2, 'g');
      val = val.replace(re2, this.getConfig("titleOperation"));

      return val;
  }

  getLabelNewLine(key) {
    let value = this.getLabel(key)
    return value.split(/<br\/>|\r\n/).map((item, key) => {
      return <div key={key}>{item}</div>
    });
  }
  

  getConfig = (key) => {
    return this.getLabel('CONFIG.'+key, false)
  }

  getConfigNewLine(key) {
    let value = this.getConfig(key)
    return value.split(/<br\/>|\r\n/).map((item, key) => {
     return <div key={key}>{item}</div>
    });
  }

  merge(target, source) {
      for (const key of Object.keys(source)) {
          if (source[key] instanceof Object) {
              Object.assign(source[key], this.merge(target[key] || {}, source[key]));
          }
      }

      Object.assign(target || {}, source);

      return target;
  }
}

Wording._instance = null

export default Wording.getInstance()