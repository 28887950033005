import React, { Component } from 'react'
import LogOutIcon from '../../assets/img/logout.png';
import { Wording } from '../../library';

export default class Logout extends Component {

  wording = Wording;

  render() {
    const { OpenModal } = this.props;
    return (
    <div className="block-button logout">
        <div className="btn black round btn-exit" onTouchStart={()=> {}} onClick={OpenModal}>
          <img src={LogOutIcon} className="logo-logout" title={this.wording.getLabel("DEFI_TRI_LIVE.LOG_OUT")} alt={this.wording.getLabel("DEFI_TRI_LIVE.LOG_OUT")}/>
        </div>
    </div>
    )
  }
}