import React from "react";

import { withRouter } from "react-router-dom";
import Screen from "../../components/Screen";
import QrReader from "react-qr-reader";
import { Game } from "../../logic";

import ScanIconRed from "../../assets/img/qr-code_red.png";
import ScanIconRefresh from "../../assets/img/refresh.png";

class QRCode extends Screen {
  constructor(props) {
    super(props);
    this.state = Object.assign(this.state, {
      result: "No result",
      waste: null,
      wrongQRCode: false,
      mounted: false,
      correctQRCode: false,
    });

    this.handleError = this.handleError.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.skip = this.skip.bind(this);
    this.game = Game;

    this.wrongQRCodeHandler = false;
    this.wasteSortingTimout = false;
  }

  componentDidUpdate() {
    if (this.state.loaded && !this.state.waste) {
      window.scrollTo(0, 0);
      const waste = this.game.getWaste();
      this.setState({ mounted: true });
      if (!waste) {
        console.log("no waste found");
        // this.redirect('/score')
        return;
      }
      // console.log(waste);
      this.setState({ waste });
    }
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
    if (this.wrongQRCodeHandler) {
      window.clearTimeout(this.wrongQRCodeHandler);
    }
    if (this.wasteSortingTimout) {
      window.clearTimeout(this.wasteSortingTimout);
    }
    this.wrongQRCodeHandler = null;
    this.wasteSortingTimout = null;
  }

  skip() {
    console.log("QR Code skip");
    const waste = this.game.skip();
    this.setState({
      waste,
    });
  }

  fakeScan() {
    this.setState({ result: this.state.waste.qrCode });
    this.setState({ correctQRCode: true });
    this.wasteSortingTimout = setTimeout(() => {
      if (this.wasteSortingTimout) {
        this.props.goToSort();
      }
    }, 1000);
  }

  handleScan(data) {
    if (data) {
      console.log(
        "scan",
        data,
        "wasteId",
        this.state.waste.id,
        this.state.waste.qrCode
      );
      if (
        data !== this.state.waste.qrCode &&
        process.env.NODE_ENV !== "development"
      ) {
        // for test only
        this.setState({ wrongQRCode: true });

        if (this.wrongQRCodeHandler) {
          window.clearTimeout(this.wrongQRCodeHandler);
        }

        this.wrongQRCodeHandler = window.setTimeout(() => {
          this.setState({ wrongQRCode: false });
          this.wrongQRCodeHandler = null;
        }, 2000);
      } else {
        this.setState({ correctQRCode: true });
        this.wasteSortingTimout = setTimeout(() => {
          if (this.wasteSortingTimout) {
            this.props.goToSort();
          }
        }, 1000);
      }

      this.setState({ result: data });
    }
  }

  handleError(err) {
    console.log({ err });
  }

  getOtherProduct() {
    console.log("new product");
  }

  // used to go next without scan validation (dev mode)
  next(){
    this.setState({ correctQRCode: true });
    this.wasteSortingTimout = setTimeout(() => {
      if (this.wasteSortingTimout) {
        this.props.goToSort();
      }
    }, 1000);
  }

  render() {
    return (
      <div className="content-page">
        <div className="block-product">
          {!!this.state.waste && (
            <div className="content-product">
              <img
                src={this.game.getImage(this.state.waste.imageUrl)}
                className="product-picture"
                alt={this.state.waste.label}
              />
              <div className="name-product">{this.state.waste.label}</div>
            </div>
          )}
          {this.state.wrongQRCode && (
            <div className="block-verif-scan">
              <img
                src={ScanIconRed}
                className="logo-scan"
                title={this.wording.getLabel("DEFI_TRI_LIVE.QR_CODE.SCAN")}
                alt={this.wording.getLabel("DEFI_TRI_LIVE.QR_CODE.SCAN")}
              />
              {/* <img src={ScanIconIfValid} className="logo-valid" alt="Valid Scan Qr-Code"/> */}
            </div>
          )}
          <div className="block-change-prod" onClick={() => this.skip()}>
            <img
              src={ScanIconRefresh}
              className="logo-scan"
              title={this.wording.getLabel("DEFI_TRI_LIVE.QR_CODE.REFRESH")}
              alt={this.wording.getLabel("DEFI_TRI_LIVE.QR_CODE.REFRESH")}
            />
            {/* <img src={ScanIconIfValid} className="logo-valid" alt="Valid Scan Qr-Code"/> */}
          </div>
        </div>

        {this.state.mounted && (
          <div className={this.state.wrongQRCode ? "error-scan" : "valid-scan"}>
            <div
              className={this.state.correctQRCode ? "correct-scan" : "no-scan"}
            >
              <QrReader
                delay={300}
                onError={this.handleError}
                onScan={this.handleScan}
                className="scan-video"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        )}

        {process.env.NODE_ENV === "development" ? (
        <button
            className="btn default"
            onClick={() => {
              this.next();
            }} >
            <span>Valider Scan (cheat pour debug)</span>
        </button>
        ) : null}
      </div>
    );
  }
}

export default withRouter(QRCode);
