import {assetUrl} from "../../helpers/urlHelper";

class Game {
  static getInstance() {
    if (!Game._instance) {
      Game._instance = new Game()
    }

    return Game._instance
  }

  constructor() {
    const wastes = null
    const wastebins = null
    this.operationName = null;
    this.wastes = wastes;
    this.wastebins = wastebins;
    this.isRandomOrder = false;
    this.init();
  }

  init() {
    // this.availableIndexes = Object.keys(this.wastes);
    this.score = 0

    this.workWaste = null
    this.operationName = null;
    this.workIndex = -1
    this.workRealIndex = -1
    this.finalTime = 0

    this.answers = []

    //this.preloadWastesImages();
    //this.preloadWastebinsImages();
  }

  updateWasteDetail(config, operationName) {
     
      if(config.randomMaterialOrder === undefined) config.randomMaterialOrder = true;

      // Only update if the operationNameChange
      if (operationName && this.operationName !== operationName) {
        // console.log('response', config.activeDTLConsignes, config.activeDTLProduits);
        this.operationName = operationName;
        this.wastes = config.activeDTLProduits;
        this.wastebins = config.activeDTLConsignes;
        this.isRandomOrder = config.randomMaterialOrder;
        this.availableIndexes = Object.keys(this.wastes);   // les index encore disponibles (sont ecretes au fur et a mesure)
        this.preloadWastesImages();
        this.preloadWastebinsImages()
      }
  }

  restartGame() {
    this.availableIndexes = Object.keys(this.wastes)
  }

  getImage(image) {
    return assetUrl(image)
  }

  preloadWastesImages() {
    // console.log('preloadWastesImages', this.wastes);
    Object.keys(this.wastes).map((waste) => {
      const img = new Image();
      img.src =  this.wastes[waste].imageUrl
      return false
    })
  }

  preloadWastebinsImages() {
    // console.log('preloadWastebinsImages', this.wastebins);
    Object.keys(this.wastebins).map((wastebin) => {
      const img = new Image();
      img.src =  this.wastebins[wastebin].imageUrl
      return false
    })
  }

  getWaste() {
    // console.log("nb indexes", this.availableIndexes, this.availableIndexes.length)

    if(this.isRandomOrder){
      // Choose at random an element from the availableIndexes
      const index = Math.floor(Math.random() * this.availableIndexes.length)
  
      // Get realIndex of random element (because, realIndex can be different from 0,1,2...) and remove waste from availableIndexes
      const realIndex = this.availableIndexes.splice(index, 1)[0]
      // Get corresponding waste
      const waste = this.wastes[realIndex]
  
      this.workWaste = waste
      this.workIndex = index
      this.workRealIndex = realIndex
      // console.log('getWaste', this.availableIndexes.length, index, realIndex, this.wastes, waste);
  
      // Return workWaste (currentWaste)
      return waste
    }else{
      // Choose the first element of availableIndexes
      const index = 0;
  
      // Get realIndex of random element (because, realIndex can be different from 0,1,2...) and remove waste from availableIndexes
      const realIndex = this.availableIndexes.splice(index, 1)[0];

      // Get corresponding waste
      const waste = this.wastes[realIndex]
  
      this.workWaste = waste
      this.workIndex = index
      this.workRealIndex = realIndex
      // console.log('getWaste', this.availableIndexes.length, index, realIndex, this.wastes, waste);
  
      // Return workWaste (currentWaste)
      return waste
    }
  }

  getWastebinByIndex(index) {
    return this.wastebins[index];
  }

  getWastebinByWastebinId(index) {
    // return this.wastebins[index];
    // console.log('getWastebinByWastebinId', this.wastebins);
    var result = null;
    this.wastebins.forEach(function(wastebin){
      // console.log(wastebin, wastebin.id, index, wastebin.id == index)
      if (wastebin.id === index) {
        result = wastebin;
      }
    });
    return result;
  }

  // Liste des wastebins à afficher pour le sorting
  getWastebins() {
    return this.wastebins;
  }

  skip() {
    this.availableIndexes.splice(this.workIndex, 0, this.workRealIndex) // insert back current Waste in the availableIndexes
    // console.log('skip this.availableIndexes', this.workIndex, this.workRealIndex, this.availableIndexes);
    //Put the current index at the end
    this.availableIndexes.push(this.availableIndexes.shift());

    // Get next Waste
    return this.getWaste()
  }

  hasNext() {
    // console.log('hasNext', this.availableIndexes, this.availableIndexes.length > 0);
    return this.availableIndexes.length > 0
  }

  getCurrentWaste() {
    // console.log('getCurrentWaste', this.workWaste, this.workIndex, this.workRealIndex, this.availableIndexes);
    return this.workWaste
  }

  getScore() {
    return this.score
  }

  setFinalTime(time) {
    this.finalTime = time
  }

  getFinalTime() {
    return this.finalTime
  }

  answer(wasteBin) {
    // console.log('answer');
    if (this.workWaste) {
      // console.log('answer compare', this.workWaste, wasteBin)
      const goodAnswer = this.workWaste.consigneId === wasteBin.id
      this.answers.push({
        waste: this.workWaste,
        answeredId: wasteBin.id,
        goodAnswer
      })
  
      if (goodAnswer) {
        this.score++
      }
    } else {
      // no workWaste set
      // console.log('no workWaste set');
    }
  }

  getAnswers() {
    return this.answers
  }

}

Game._instance = null

export default Game.getInstance()
