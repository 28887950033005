import React, { Component } from 'react'

import Tick from '../../assets/img/tick.png';

export default class ValidationWaste extends Component {
    render() {
        const ClassOpenValidation = this.props.ValidationIsOpen ? 'show' : '';
        return (
        <div className={"validationwaste-block " + ClassOpenValidation}>
            <div className="content-validation">
                <img src={Tick} alt=''/>
            </div>
        </div>
        )
    }
}