import React, { Component } from "react";
import { Wording } from "../../library";
import Chrono from "../Chrono/Chrono";
import Logo from "../Logo/Logo";
import {assetUrl} from "../../helpers/urlHelper";

export default class Footer extends Component {
  wording = Wording;

  constructor(props) {
    super(props);
    this.chrono = React.createRef();
  }

  render() {
    return (
      <div className="header-block">
        {this.props.withChrono &&
          this.wording.getConfig("hasChrono") === true ? (
          <Chrono ref={this.chrono} redirect={() => this.props.redirect()} />
        ) : (
          <img
            src={assetUrl(this.wording.getConfig("headerLogoUrl"))}
            className="logo-chrono"
            alt={this.wording.getConfig("headerLogoAlt")}
          />
        )}

        <div className="name-page-block">
          <Logo
            logoUrl={assetUrl(this.wording.getConfig("footerLogoUrl"))}
            logoAlt={this.wording.getConfig("footerLogoAlt")}
          />
        </div>

        <div className="name-page-block">
          <div className="name-page">{this.props.NamePage}</div>
          <div className="line-pict"></div>
        </div>
      </div>
    );
  }
}
