import React from "react";

import { withRouter } from "react-router-dom";
import Screen from "../../components/Screen";
import { Game } from "../../logic";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import HeadProduct from "../../assets/img/head_product.png";
import HeadWaste from "../../assets/img/head_waste.png";
import HeadValid from "../../assets/img/head_valid.png";
import IconOK from "../../assets/img/valid.png";
import IconKO from "../../assets/img/novalid.png";

class Score extends Screen {
  constructor(props) {
    super(props);

    this.state = Object.assign(this.state, {
      answers: [],
      score: 0,
      finalTime: 0,
      answerCount: 0,
      formParticipation:
        window.sessionStorage.getItem("formParticipation") || false,
      participationId:
        window.sessionStorage.getItem("formParticipationId") || false,
      errorParticipation: false,
    });

    this.game = Game;
  }

  componentDidMount() {
    const answers = this.game.getAnswers();
    const score = this.game.getScore();
    const finalTime = this.game.getFinalTime();
    const answerCount = answers.length;
    console.log("score componentDidMount", answers, score, finalTime, answerCount);

    this.setState({ answers, score, finalTime, answerCount }, () => this.sendScore(null));
    // if (!this.state.formParticipation) {
    //   this.sendScore(null);
    // }
    // this.sendScore(null);
  }

  goToEnding = (event) => {
      if (!!event) event.preventDefault();

      this.redirect("/ending")
  }

  sendScore = (event) => {
    if (!!event) event.preventDefault();

    const { answerCount, score, formParticipation, participationId } =
      this.state;

      console.log("score sendScore", answerCount, score, formParticipation, participationId, this.state);
    this.api
      .validateScore({
        ...JSON.parse(formParticipation),
        answerCount,
        score,
        participationId,
        operationId: this.wording.getConfig("id"),
        time: this.state.finalTime,
      })
      .then((response) => {
        if (response.error) {
          throw response;
        }
        if ("detail" in response || "error" in response) throw response;
        // this.redirect("/ending");
      })
      .catch((e) => {
        console.log("Error:", e);
        if (e["detail"] === "email_already_participating") {
          this.setState({ errorParticipation: true });
        } else if (e["error"] === "malformatted_request_not_json") {
          console.log("Error while sending data : json malformated");
        } else if (e["error"] === "malformatted_invalid_json") {
          console.log("Error while sending data : json malformated");
        } else if (e["error"] === "json_missing_parametter") {
          console.log("Error while sending data : parameters misssing");
          if (e["mandatory_parameters"] !== undefined) {
            console.log(
              "List of mandatory parameters : " +
                e["mandatory_parameters"].join(", ")
            );
          }
        } else if (e["error"] === "operation_not_found") {
          console.log("Error while sending data : operation not found");
        } else if (e["error"] === "participation_not_saved") {
          console.log("Error while sending data : participation not created");
        }
        // this.redirect('/ending');
      });
  };

  renderTableBody() {
    // console.log("renderTableBody", this.state);
    return this.state.answers.map((answer, key) => {
      const wastebin = this.game.getWastebinByWastebinId(answer.answeredId);
      // console.log(answer.answeredId, wastebin);
      const ifValidPict = answer.goodAnswer ? IconOK : IconKO;
      const ifValidAlt = answer.goodAnswer
        ? this.wording.getLabel("DEFI_TRI_LIVE.SCORE.TRUE")
        : this.wording.getLabel("DEFI_TRI_LIVE.SCORE.FALSE");
      return (
        <div className="content-result" key={key}>
          <img
            src={this.game.getImage(answer.waste.imageUrl)}
            className="result-product"
            alt={answer.waste.label}
          />
          <div className="waste" style={{ backgroundColor: wastebin.color }}>
            <img
              src={this.game.getImage(wastebin.imageUrl)}
              className="waste-picture"
              alt={wastebin.label}
            />
          </div>
          <img src={ifValidPict} className="result-valid" alt={ifValidAlt} />
        </div>
      );
    });
  }

  render() {
    const { formParticipation, errorParticipation } = this.state;
    return (
      <div className="score-page page">
        <Header
          NamePage={this.wording.getLabelNewLine(
            "DEFI_TRI_LIVE.SCORE.PAGE_NAME"
          )}
        />

        <div className="content-page">
          <div className="score-text">
            {this.state.score} / {this.state.answerCount}
          </div>
          {this.wording.getConfig("hasChrono") === true && (
            <div className="time-text">en {this.state.finalTime} secondes</div>
          )}
          <div className="block-result">
            <div className="head-result">
              <img
                src={HeadProduct}
                className="head-product"
                alt={this.wording.getLabel("DEFI_TRI_LIVE.SCORE.PRODUCT")}
              />
              <img
                src={HeadWaste}
                className="head-waste"
                alt={this.wording.getLabel("DEFI_TRI_LIVE.SCORE.TRASH")}
              />
              <img
                src={HeadValid}
                className="head-valid"
                alt={
                  this.wording.getLabel("DEFI_TRI_LIVE.SCORE.TRUE") +
                  "/" +
                  this.wording.getLabel("DEFI_TRI_LIVE.SCORE.FALSE")
                }
              />
            </div>
            {this.renderTableBody()}
          </div>
        </div>
        <div className="bottom-page">
          <div className="buttons">
            {(
              <div className="btn-wrapper">
                {errorParticipation && (
                  <div className="errorMessage">
                    {this.wording.getLabelNewLine(
                      "ERROR.API.EMAIL_ALREADY_PARTICIPATING"
                    )}
                  </div>
                )}
                <div
                  className="btn default btn-participe"
                  onClick={this.goToEnding}
                >
                  <span>
                      { this.wording.getLabel(formParticipation ? "DEFI_TRI_LIVE.SCORE.BTN" : "DEFI_TRI_LIVE.SCORE.BTN_NOT_SUB") }
                  </span>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(Score);
