import React from "react";

import { withRouter } from "react-router-dom";
import Screen from "../../components/Screen";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import Chrono from "../../assets/img/chrono-yellow.png";

class Validate extends Screen {
  constructor(props) {
    super(props);
    this.state = Object.assign(this.state, {
      isSub: !!window.sessionStorage.getItem("formParticipation"),
      withChrono: this.wording.getConfig("hasChrono") === true,
      participationId:
        window.sessionStorage.getItem("formParticipationId") || false,
      operationId: this.wording.getConfig("id") || false,
    });
  }

  startGame() {
    window.sessionStorage.setItem("gameReady", true);
    this.redirect("/scan");
  }

  render() {
    const isSubKey = this.state.isSub ? "_SUB" : "_NOT_SUB";
    const withChronoKey = this.state.withChrono ? "_CHRONO" : "_NO_CHRONO";
    return (
      <div className="validate-page page">
        <Header
          NamePage={this.wording.getLabelNewLine(
            "DEFI_TRI_LIVE.VALIDATE.PAGE_NAME"
          )}
        />
        <div className="content-page">
          <div className="title-page">
            {this.wording.getLabelNewLine(
              "DEFI_TRI_LIVE.VALIDATE.PAGE_TITLE" + isSubKey
            )}
          </div>
          <div className="text-page">
            <div>
              {this.wording.getLabelNewLine(
                "DEFI_TRI_LIVE.VALIDATE.TEXT" + isSubKey + withChronoKey
              )}
            </div>
            {this.state.withChrono && (
              <div id="chrono">
                <img
                  src={Chrono}
                  alt={this.wording.getLabel(
                    "DEFI_TRI_LIVE.GAMEOVER.CHRONO_ALT"
                  )}
                />
                <div className="chrono-text">
                  <div className="time">
                    {this.wording.getConfig("chronoDuration")}
                  </div>
                  <div className="unit">
                    {this.wording.getLabelNewLine(
                      "DEFI_TRI_LIVE.GAMEOVER.CHRONO_UNIT"
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <button
            className="btn default btn-inscription"
            type="submit"
            onClick={() => {
              this.startGame();
            }}
          >
            <span>{this.wording.getLabel("DEFI_TRI_LIVE.VALIDATE.BTN")}</span>
          </button>
        </div>
        <div className="bottom-page">
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(Validate);
