import {getApiUrl} from "../../helpers/urlHelper";

class Api {
  constructor(operation) {
    this.apiToken = '0d48c290-f287-42af-9c39-2f0067abae15';
    this.operation = operation;
    this.isLocal = process.env.NODE_ENV === "development";
    this.conf = {
      rootUrl: getApiUrl() + "/api/",
      endpointExt: "",
    }
  }

  get(endpoint) {
    return new Promise((resolve, reject) => {
      const param = {
        method: "POST",
        headers: {
          'x-auth-token': this.apiToken
        },
      };
      fetch(this.conf.rootUrl + endpoint + this.conf.endpointExt, param)
        .then((response) => this.handleErrors(response))
        .then((response) => resolve(response.json()))
        .catch((error) => reject(error));
    });
  }

  post(endpoint, data) {
    return new Promise((resolve, reject) => {
      const param = {
        method: this.isLocal ? "GET" : "POST",
        headers: {
          'x-auth-token': this.apiToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      if (!this.isLocal) param.body = JSON.stringify(data);
      fetch(this.conf.rootUrl + endpoint + this.conf.endpointExt, param)
        // .then(response => this.handleErrors(response))
        .then((response) => resolve(response.json()))
        .catch((error) => reject(error));
    });
  }

  handleErrors(response) {
    // console.log('response', response)
    if (!response.ok) {
      console.log("Api in error", response);
      throw Error("API in error");
    }
    return response;
  }

  getWording() {
    return this.get(`operation/${this.operation}/wording`);
  }
  getDetail() {
    return this.get(`operation/${this.operation}/detail`);
  }
  checkEmail(data) {
    return this.post("d_t_l_participation_check", data);
    // return this.post("d_t_l_participation_check-ko", data);
  }
  addPlayer(data) {
    return this.post("players", data);
  }
  scoreCreate(data) {
    console.log("d_t_l_empty_scoring_create", data);
    return this.post("d_t_l_empty_scoring_create", data);
  }
  updateScore(data) {
    const endpoint = data.email
      ? "d_t_l_participation_update"
      : "d_t_l_empty_scoring_update";
    if (!data.email) {
      delete data.email;
    }
    console.log(endpoint, data);
    return this.post(endpoint, data);
  }
  validateScore(data) {
    console.log('validateScore', data);
    const endpoint = data.email
      ? "d_t_l_participation_validate"
      : "d_t_l_empty_scoring_validate";
    console.log(endpoint, data);
    return this.post(endpoint, data);

    // return this.post('d_t_l_participations-ko', data)
  }
}
export default Api;
